import React from 'react';
import {inject, observer} from 'mobx-react';

import Header from 'Components/Header';
import Footer from 'Components/Footer';
import Advantage from 'Components/Advantage';
import Quiz from 'Components/Quiz';
import PrivateInfo from 'Components/PrivateInfo';

import Modal from 'Components/modal';

import './scss/main.scss';

@inject('app')
@observer
export default class Main extends React.Component {
    constructor() {
        super();
    }
    render() {
        const {app} = this.props;
        return (
            <div className='page'>
                <div className='page__left'>
                    <div className='page-content'>
                        <Header/>
                        <div className='content'>
                            <div className='content__quiz'>
                                <Quiz/>
                            </div>
                            <div className='content__advantage'>
                                <Advantage/>
                            </div>
                        </div>
                        <Footer/>
                    </div>
                </div>
                <div className='page__right'>
                    <div className={'page-image page-image--' + app.activeStep}/>
                </div>



                { app.openPrivate ?
                    <Modal closeLink={true}
                           isOpen={app.openPrivate}
                           onClose={() => {
                               app.setPrivate(false);
                           }}>
                        <PrivateInfo/>
                    </Modal> : ''
                }
                { app.openSuccess ?
                    <Modal closeLink={true}
                           isOpen={app.openSuccess}
                           onClose={() => {
                               app.changeSuccess(false);
                           }}>
                        <div className='success-modal'>
                            <p>Заявка успешно отправлена</p>
                        </div>
                    </Modal> : ''
                }
            </div>
        );
    }
}
