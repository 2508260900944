import {types, getEnv, getSnapshot, flow} from "mobx-state-tree"

import {FormStore} from './FormStore';


export const Option = types
    .model("Option", {
        id: types.identifier(types.number),
        active: false,
        type: 'radio',
        name: '',
        description: '',
        customValue: false,
        value: '',
        linePlaceHolder: '',
    })
    .actions(self => ({
        setActive(value) {
            self.active = value;
        },
        setValue(value) {
            self.value = value;
        },
        triggerActive(options) {
            options.setActive(!options.active);
        }
    }));

export const Step = types
    .model("Step", {
        number: types.identifier(types.number),
        title: '',
        subTitle: '',
        emailParam: '',
        success: false,
        items: types.optional(types.array(Option), []),
        form: types.optional(FormStore, {}),
    })
    .views(self => ({
        validate() {
            if (self.success) {
                return self.form.isValid;
            } else {
                return self.items.filter((it) => it.active).length;
            }
        },
        triggerActive(options) {
            switch (options.type) {
                case 'checkbox':
                    options.setActive(!options.active);
                    break;
                case 'radio':
                    if (!options.active) {
                        self.items.map((it) => {
                            it.setActive(false);
                        })
                    }
                    options.setActive(!options.active);
                    break;
            }
        }
    }));

export const AppStore = types
    .model("AppStore", {
        steps: types.optional(types.array(Step), []),
        activeStep: 1,
        name: '',
        titleLogo: '',
        phone: '',
        phoneLink: '',
        address: '',
        email: '',
        openPrivate: false,
        openSuccess: false
    })
    .views(self => ({
        get fetch() {
            return getEnv(self).fetch
        },
        viewsState() {
            console.log(getSnapshot(self))
        },
        afterCreate() {

            //self.viewsState();
        },
        getStep(number) {
            return self.steps.find((it) => it.number === number);
        }
    }))
    .actions(self => ({
        setPrivate(value) {
            self.openPrivate = value;
        },
        changeSuccess(value) {
            self.openSuccess = value;
        },
        nextStep() {
            if (self.activeStep < self.steps.length) {
                self.activeStep++;
            }
        },
        success: flow(function* success() {
            try {
                let data = [];
                self.steps.map((item) => {
                    let value = '';
                    item.items.map((option) => {
                        if (option.active) {
                            switch (option.type) {
                                case 'checkbox':
                                case 'radio':
                                    value += option.name;
                                    break;
                            }
                        }
                        if (option.customValue) {
                            value += option.value;
                        }
                    });
                    if (value.length) {
                        data.push({
                            name: item.emailParam,
                            value: value
                        });
                    }
                    if (item.success) {
                        const phone = item.form.getField('phone');
                        const email = item.form.getField('email');
                        data.push({
                            name: 'Номер телефона',
                            value: phone.value
                        });
                        data.push({
                            name: 'Email',
                            value: email.value
                        })
                    }
                });
                const line = window.location.search.match(/\?(.+)/);
                if (line !== null) {
                    const url = line[1].split('&');
                    let utm = [];
                    let lines = '';
                    url.forEach(function (entry) {
                        lines += entry.split('=')[0] + '=' + decodeURI(entry.split('=')[1]) + ';';

                        if (entry.split('=')[0] === 'utm_source') {
                            utm.push({
                                name: 'Рекламная система',
                                value: decodeURI(entry.split('=')[1])
                            })
                        }
                        if (entry.split('=')[0] === 'utm_medium') {
                            utm.push({
                                name: 'Тип трафика',
                                value: decodeURI(entry.split('=')[1])
                            })
                        }
                        if (entry.split('=')[0] === 'utm_campaign') {
                            utm.push({
                                name: 'Название рекламной компании',
                                value: decodeURI(entry.split('=')[1])
                            })
                        }
                        if (entry.split('=')[0] === 'utm_term') {
                            utm.push({
                                name: 'Ключевые слова',
                                value: decodeURI(entry.split('=')[1])
                            })
                        }
                        if (entry.split('=')[0] === 'utm_content') {
                            utm.push({
                                name: 'Содержание текста ссылки',
                                value: decodeURI(entry.split('=')[1])
                            })
                        }

                    });
                    data.push({
                        name: 'GET PARAMS',
                        value: lines
                    });
                    if (utm.length){
                        data.push({
                            name: 'UTM',
                            items: utm
                        });
                    }
                }
                data.push({
                    name: 'ADDRESS',
                    value: window.location.hostname + window.location.pathname
                });
                const json = yield self.fetch.Order.save({
                    info: data
                });
                if (json && json.success) {
                    if (window.successTrack) {
                        window.successTrack();
                    }
                    self.changeSuccess(true);
                }
            } catch (err) {
                console.error("Error save order", err)
            }
        }),
    }));
