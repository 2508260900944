import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

//import CloseIco from 'Kit/icons/cross';
//import Loader from 'Kit/loader';

//import styles from './styled.scss';

export const view = {
    values: [
        'default',
        'mobile',
        'dark'
    ],
    default: 'default',
};
export const size = {
    values: ['default', 'small', 'large'],
    default: 'default',
};


export default class Modal extends React.Component {
    static propTypes = {
        isOpen: PropTypes.bool,
        view: PropTypes.oneOf(view.values),
        size: PropTypes.oneOf(size.values),
        onClose: PropTypes.func,
        closeLink: PropTypes.bool,
    };

    static defaultProps = {
        value: 0,
        isOpen: false,
        view: view.default,
        size: size.default,
        closeLink: true
    };

    constructor() {
        super();
    }

    componentDidMount() {
        document.documentElement.classList.add('body-open-modal')
    }

    componentWillUnmount() {
        document.documentElement.classList.remove('body-open-modal')
    }

    render() {
        const {props} = this;

        if (!props.isOpen) {
            return '';
        }

        const classH = classNames('modal', {
            [`modal-size-${props.size}`]: true,
            [`modal-view-${props.view}`]: true,
            'modal-open': props.isOpen
        });
        return (
            <div className={classH}>
                <div className="modal-overlay"
                     onClick={() => {
                         if (this.props.onClose) {
                             this.props.onClose()
                         }
                     }}/>
                <div className="modal-box">
                    {props.children}
                    {props.closeLink ?
                        <span className="modal-close"
                              onClick={(e) => {
                                  if (this.props.onClose) {
                                      this.props.onClose()
                                  }
                              }}>
                            Закрыть
                            <span className='modal-close__ico'/>
                        </span> : ''
                    }
                    {/*{ props.load ?*/}
                        {/*<Loader/> : ''*/}
                    {/*}*/}
                </div>
            </div>
        );
    }
}
