import React from 'react';
import {inject, observer} from 'mobx-react';

import StepsBar from './StepsBar';
import Advantage from 'Components/Advantage';

@inject('app')
@observer
export default class StepRadio extends React.Component {
    render() {
        const {step} = this.props;
        const list = step.items.map((item, key) => {
            return (
                <div className={'quiz-list-col'  + (item.description.length ? ' quiz-list-col-big' : '')}
                     key={key}>

                    {!item.customValue ?
                        <div
                            className={'quiz-radio' + (item.active ? ' quiz-radio-active' : '') + (item.description.length ? ' quiz-radio-big' : ' quiz-radio-base')}
                            onClick={() => {
                                step.triggerActive(item);
                            }}>
                            <div className='quiz-radio__check'/>
                            <div className='quiz-radio__title'>
                                {item.name}
                            </div>
                            {item.description.length ?
                                <div className='quiz-radio__info'>
                                    <p>{item.description}</p>
                                </div> : ''
                            }
                        </div> :
                        <div
                            className={'quiz-radio quiz-radio-numbers quiz-radio-numbers-line' + (item.active ? ' quiz-radio-active' : '')}
                            onClick={() => {
                                step.triggerActive(item);
                            }}>
                            <div className='quiz-radio__check'/>
                            <div className='form-group'>
                                <input className='form-control'
                                       type='text'
                                       placeholder='Введите другое'
                                       onClick={(e) => {
                                           if (item.active) {
                                               e.preventDefault();
                                               e.stopPropagation();
                                           }
                                       }}
                                       onChange={(e) => {
                                           item.setValue(e.target.value);
                                       }}/>
                            </div>
                        </div>
                    }
                </div>
            )
        });
        return (
            <div>
                <div className='quiz-header'>
                    <div className='quiz__title' dangerouslySetInnerHTML={{__html: step.title}}/>
                    <div className='quiz__description' dangerouslySetInnerHTML={{__html: step.subTitle}}/>
                    <StepsBar/>
                </div>
                <div className='quiz-body'>
                    <div className='quiz-list quiz-list-1'>
                        <div className='quiz-list-row'>
                            {list}
                        </div>
                    </div>
                    <Advantage/>
                </div>
            </div>
        )
    }
}
