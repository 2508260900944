import React from 'react';
import {inject, observer} from 'mobx-react';

@inject('app')
@observer
export default class StepsBar extends React.Component {
    render() {
        const {app} = this.props;

        const list = app.steps.map((it, key) => {
            if (!it.success) {
                return (
                    <div className={'quiz-steps-btn' + (it.number <= app.activeStep ? ' quiz-steps-btn-active' : '')}
                         key={key}>
                        Шаг {it.number}
                    </div>
                )
            }
        });
        return (
            <div className='quiz-steps'>
                <div className='quiz-steps-list'>
                    {list}
                </div>
            </div>
        )
    }
}
