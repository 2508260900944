import React from 'react';
import {inject, observer} from 'mobx-react';

import StepRadio from './StepRadio';
import StepSuccess from './StepSuccess';

@inject('app')
@observer
export default class Quiz extends React.Component {
    render() {
        const {app} = this.props;

        let Step = StepRadio;
        let StepProps = {};


        StepProps.step = app.getStep(app.activeStep);

        if (StepProps.step.success) {
            Step = StepSuccess;
        }
        return (
            <div className={'quiz' + (StepProps.step.success ? ' quiz--success' : '')}>
                <Step {...StepProps}/>
                <div className='quiz-footer'>
                    {app.activeStep === app.steps.length ?
                        <a className={'btn btn-success ' + (StepProps.step.validate() ? '' : 'disabled')}
                           onClick={() => {
                               app.success()
                           }}>Получить смету с расчетом</a>
                        :
                        <a className={'btn btn-success ' + (StepProps.step.validate() ? '' : 'disabled')}
                           onClick={() => {
                               app.nextStep()
                           }}>cледующий шаг</a>
                    }
                </div>
            </div>
        )
    }
}
