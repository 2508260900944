import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'mobx-react';
import { types } from 'mobx-state-tree';

import MainPage from './views/App';
import {AppStore} from "./stores/AppStore"

import agent from './agent';

let dataSteps = {};

if (window.dataSteps) {
    dataSteps = window.dataSteps;
}
const app = AppStore.create(
    // {
    //
    //     // steps: dataSteps.steps,
    //     // name: dataSteps.name,
    //     // titleLogo: dataSteps.titleLogo
    // },
    dataSteps,
    {
       fetch: agent,
    }
);

const startApp = (() => {
    ReactDOM.render(
        <Provider app={app}>
            <MainPage/>
        </Provider>,
        document.getElementById('root')
    );
});

startApp();