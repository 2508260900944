import React from 'react';
import {inject, observer} from 'mobx-react';

@inject('app')
@observer
export default class Header extends React.Component {
    render() {
        const {app} = this.props;
        return(
            <div className='header'>
                {/*<div className='header-mobile'>*/}
                    {/*<div className='header-mobile__inner'>*/}
                        {/*<a href={'tel:' + app.phoneLink} className='header__phone'>{app.phone}</a>*/}
                    {/*</div>*/}
                {/*</div>*/}
                <div className='group-logo'>
                    <a href='/' className='logo'/>
                    <div className='header-adv-group'>
                        <div className='header-adv'>
                            <span className='header-adv__ico'/>
                            <span className='header-adv__text'>
                                Ремонт фасадов любой сложности под ключ по регламентам и ГОСТ
                            </span>
                        </div>
                        <div className='header-adv'>
                            <span className='header-adv__ico'/>
                            <span className='header-adv__text'>
                                Мы дилеры заводов - материалы без наценки
                            </span>
                        </div>
                    </div>
                </div>
                <a href={'tel:' + app.phoneLink} className='header__phone'>{app.phone}</a>
            </div>
        )
    }
}
