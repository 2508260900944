import {types, getEnv, flow} from "mobx-state-tree"

import {FieldForm, Validator} from './FormsStore'

export const FormStore = types
    .model("FormStore", {
        fields: types.optional(types.array(FieldForm), [
            {
                name: 'phone',
                value: '',
                error: '',
                rule: 'required'
            },
            {
                name: 'email',
                value: '',
                error: '',
                rule: 'required'
            }
        ]),
        isValid: false,
        error: ''
    })
    .views(self => ({
        get app() {
            return getEnv(self).app
        },
        getField(name) {
            return self.fields.find((item) => item.name === name);
        }
    }))
    .actions(self => ({
        /**
         * Изменение поля
         * @param field {string}  имя поля
         * @param value {string}  значение поля
         */
        onFieldChange(field, value) {
            self.getField(field).error = '';
            self.getField(field).value = value;
            self.isValidation();
        },
        isValidation() {
            let phone = self.getField('phone');
            let email = self.getField('email');

            let validation = new Validator(
                {phone: phone.value, email: email.value},
                {phone: phone.rule, email: email.rule},
            );

            self.isValid = validation.passes();

            return self.isValid;
        }

    }));
