import React from 'react';
import {inject, observer} from 'mobx-react';

import Advantage from 'Components/Advantage';

@inject('app')
@observer
export default class StepSuccess extends React.Component {
    render() {
        const {step} = this.props;
        let phone = step.form.getField('phone');
        let email = step.form.getField('email');
        return (
            <div>
                <div className='quiz-header'>
                    <div className='quiz__title' dangerouslySetInnerHTML={{__html: step.title}}/>
                </div>
                <div className='quiz-body'>

                    <form className='quiz-list quiz-list-form'
                          onSubmit={(e) => {
                              e.preventDefault();
                              step.form.submitForm();
                          }}>
                        <div>
                            <div className='quiz-successInfo'>
                                <div className='quiz-successInfo__label'>Какие 5 услуг бесплатны :</div>
                                <ul className='quiz-successInfo__list'>
                                    <li>- обследование фасада</li>
                                    <li>- проект с колористикой</li>
                                    <li>- разработка регламента работ</li>
                                    <li>- геодезические работы</li>
                                    <li>- испытания на вырыв</li>
                                </ul>
                            </div>
                            <div className='quiz__description' dangerouslySetInnerHTML={{__html: step.subTitle}}/>
                            <div className='quiz-list-row'>
                                <div className='quiz-list-col'>
                                    <div className='form-group'>
                                        <input type='text'
                                               name={phone.name}
                                               value={phone.value}
                                               className={'form-control form-control-lg' + (phone.error.length ? 'is-invalid' : '')}
                                               onChange={(event) => {
                                                   step.form.onFieldChange(phone.name, event.target.value);
                                               }}
                                               placeholder='+7 (000) 00 - 00 - 000'/>
                                        {phone.error.length ?
                                            <div className="invalid-feedback">{phone.error}</div> : ''
                                        }
                                    </div>
                                </div>
                                <div className='quiz-list-col'>
                                    <div className='form-group'>
                                        <input type='text'
                                               name={email.name}
                                               value={email.value}
                                               className={'form-control form-control-lg' + (email.error.length ? 'is-invalid' : '')}
                                               onChange={(event) => {
                                                   step.form.onFieldChange(email.name, event.target.value);
                                               }}
                                               placeholder='Введите E-mail'/>
                                        {email.error.length ?
                                            <div className="invalid-feedback">{email.error}</div> : ''
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <Advantage/>
                </div>
            </div>
        )
    }
}
