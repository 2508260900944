import {types, getEnv, getSnapshot} from "mobx-state-tree"
import Validator from 'validatorjs';

Validator.useLang('ru');

let messages = Validator.getMessages('ru');
messages.required = 'Заполните поле';
messages.min = {
    numeric: 'Значение должно быть больше или равно :min.',
    string: 'Количество символов должно быть не менее :min.'
};

Validator.setMessages('ru', messages);


const FieldForm = types
    .model("FieldForm", {
        name: types.string,
        value: types.union(types.string, types.boolean),
        error: types.optional(types.string, ''),
        rule: types.optional(types.string, ''),
    });

export {FieldForm, Validator}
