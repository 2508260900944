import superagentPromise from 'superagent-promise';
import _superagent from 'superagent';

const superagent = superagentPromise(_superagent, global.Promise);

let API_ROOT = '/';

const handleErrors = (err) => {
    if (err) {

    }
    return err;
};

const responseBody = res => {
    const data = JSON.parse(res.text);
    return data;
};

const requests = {
    del: (url, params) =>
        superagent
            .del(`${API_ROOT}${url}`)
            .query(params)
            .end(handleErrors)
            .then(responseBody),
    get: (url, params) =>
        superagent
            .get(`${API_ROOT}${url}`)
            .query(params)
            .end(handleErrors)
            .then(responseBody),
    put: (url, body) =>
        superagent
            .put(`${API_ROOT}${url}`, body)
            .end(handleErrors)
            .then(responseBody),
    post: (url, body) =>
        superagent
            .post(`${API_ROOT}${url}`, body)
            .end(handleErrors)
            .then(responseBody)
};

const Order = {
    save: (params) =>
        requests
            .post('order.php', params)
};

export default {
    Order
};
