import React from 'react';
import {inject, observer} from 'mobx-react';

@inject('app')
@observer
export default class Footer extends React.Component {
    render() {
        const {app} = this.props;
        return (
            <div className='footer'>
                <div className='footer__left'>
                    {app.address} Email: <a href={'mailto:' + app.email} className='footer__email'>{app.email}</a>
                </div>
                <div className='footer__right'>
                    <a href="#"
                       onClick={(e) => {
                           e.preventDefault();
                           e.stopPropagation();
                           app.setPrivate(true);
                       }}
                       className='footer__private'>Политика конфидициальности</a>
                </div>
            </div>
        )
    }
}
